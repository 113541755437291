$background: #000000;
$textcolor: #ffffff;
$accent: #b1c8ff;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", serif;
}

html {
  background-color: $background;
}

body::-webkit-scrollbar {
  width: 0 !important;
}

#root a {
  text-decoration: none;
}

////////////////////////
//// KBarCommand.js ////
////////////////////////
.kbar-result {
  padding: 0.7rem;
  border-left: 3px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.kbar-result:hover {
  background: #f1f1f1;
  border-left: 3px solid $accent;
}

.kbar-result:hover .kbar-first {
  background: #f1f1f1;
}

.kbar-result kbd {
  padding: 4px 6px;
  background: #e7e7e7;
  border-radius: 4px;
  font-size: 14px;
}

.kbar-first {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fcfcfc;
}

.kbar-name-group {
  margin-left: 1rem;
}

//////////////////
//// App.js //////
/////////////////

.website-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: $background;
  color: $textcolor;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/////////////////////////
//// Navigation.js //////
////////////////////////
.nav-wrapper {
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.nav-home-link {
  color: $accent;
  font-weight: 600;
  font-size: 1.3rem;
}

.nav-paths {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-paths div {
  font-size: 1.3rem;
  margin-left: 4rem;
  font-weight: 500;
  color: $textcolor;
}

.nav-paths div:after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: $accent;
  transition: width 0.3s;
}

.nav-paths div:hover::after {
  width: 100%;
}

/////////////////////
//// Footer.js //////
/////////////////////

.footer-wrapper {
  margin-bottom: 1.5rem;
}

.footer-wrapper div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media {
  margin: 0.5rem 1rem;
  color: $textcolor;
  opacity: 0.7;
  transition: 0.2s all linear;
}

.social-media:hover {
  color: $accent;
  opacity: 1;
}

///////////////////////
//// HomePage.js //////
///////////////////////
.header-wrapper {
  padding: 0 4rem;
  width: 100%;
}

.header {
  font-size: 2.8rem;
  font-weight: 600;
  color: $textcolor;
}

.header-name {
  color: $accent;
}

.header-subtitle {
  color: $textcolor;
  font-size: 1.6rem;
}

.header-typewriter {
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: 2rem;
  color: $accent;
}

.header-hint {
  margin-top: 3.5rem;
  font-size: 1.2rem;
  font-weight: normal;
}

.header-hint kbd {
  background-color: rgb(102, 102, 102);
  border-radius: 4px;
  padding: 4px 6px;
  color: $textcolor;
}

///////////////////////
//// ProjectsPage.js //
///////////////////////
.projects-wrapper {
  margin-bottom: 2rem;
}

.projects-h1 {
  margin-top: 2rem;
  font-size: 2rem;
  font-weight: 400;
  color: white;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 2rem;
  column-gap: 3rem;
  row-gap: 3rem;
}

.ith-wrapper {
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ith-img-wrapper {
  margin: 0.7rem 0;
  padding: 0 2rem;
  transition: 0.2s margin linear;

  &:hover {
    margin-top: 0;
    margin-bottom: 1.4rem;
  }
}

.ith-image {
  width: 100%;
  border-radius: 15px;
}

.ith-info {
  margin-top: 1rem;
  font-size: 1.5rem;
  text-align: center;
  max-width: 25rem;
  font-weight: 800;
}

.ith-info p {
  margin-top: 0.5rem;
}

.ith-name {
  margin-bottom: 0.3rem;
}

.ith-subtitle {
  font-weight: 400;
  font-size: 1.2rem;
}

.ith-subtitle span {
  font-weight: bold;
  color: $accent;
}

///////////////////////
//// AboutPage.js //////
///////////////////////
.about-wrapper {
  display: flex;
  margin: 2rem 2rem;
  padding: 0 4rem;
  justify-content: space-between;
  align-items: flex-start;
}

.about-h1 {
  font-size: 2.5rem;
  margin-top: 2rem;
  margin-left: 5.2rem;
  font-weight: 400;
  color: white;
}

.about-image {
  width: 400px;
  border-radius: 10px;
  transition: 0.3s width linear;
}
.about-wrapper {
  padding: 0rem 7rem;
}
.about-content {
  width: 50%;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: normal;
  margin-right: 1.5rem;
}

.about-content span {
  color: $accent;
}

.content-section {
  font-size: 1.7rem;
  margin: 1.5rem 0 1rem 0;
}

///////////////////////
//// ProjectsInfo.js //
///////////////////////
.project-wrapper {
  padding: 0 4rem;
}
.project-link {
  color: $textcolor;
  transition: 0.2s all linear;
}

.project-link:hover {
  color: $accent;
  opacity: 1;
}
.project-desc {
  font-size: 1.1rem;
}

.project-main {
  margin: 1.5rem 0 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.project-ss {
  width: 50%;
  margin: 0.5rem 1.5rem 0.5rem 0;
  border-radius: 6px;
  transition: 0.2s width linear;
}

.motivation-wrapper {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.6rem;
}

.motivation-wrapper p {
  opacity: 0.9;
  font-size: 1.1rem;
}
.motivation-title {
  font-size: 1.4rem;
  font-weight: 500;

  margin-bottom: 0.3rem;
}
.motivation span {
  color: $accent;
}

.project-tools {
  font-size: 1.2rem;
  font-weight: 500;
}

.project-tools kbd {
  margin-left: 0.7rem;
  padding: 0.5rem;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.project-ss-sec {
  margin: 1rem 0;
  width: 100%;
  transition: 0.2s width linear;
}

/////////////////////////
//// Media Queries //////
////////////////////////
@media all and (max-width: 1024px) {
  .project-main {
    flex-direction: column;
  }

  .project-ss {
    margin: 0.5rem 0;
    width: 100%;
  }

  .motivation-wrapper {
    margin: 0.5rem 0;
  }
  .about-wrapper {
    padding: 0 3rem;
  }
  .about-image {
    width: 300px;
  }
}

@media all and (max-width: 768px) {
  .nav-wrapper {
    padding: 2rem;
  }

  .nav-home-link {
    font-size: 1.2rem;
  }

  .nav-paths div {
    font-size: 1rem;
    margin-left: 2rem;
  }

  .footer-wrapper p {
    font-size: 1rem;
    font-weight: 500;
  }

  .header-wrapper {
    padding: 0 2rem;
  }

  .header {
    font-size: 2.2rem;
  }

  .header-typewriter {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .header-subtitle {
    font-size: 1.2rem;
  }

  .header-hint {
    font-size: 1rem;
    margin-top: 2.5rem;
  }

  .about-wrapper {
    padding: 0 2rem;
  }

  .about-image {
    width: 300px;
  }

  .project-wrapper {
    padding: 0 2rem;
  }

  .projects-h1 {
    font-size: 2.5rem;
  }

  .grid-container {
    grid-template-columns: auto;
  }
}

@media all and (max-width: 600px) {
  .about-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .about-h1 {
    margin-left: 0.5rem;
  }

  .about-content {
    width: 100%;
    margin: 0 1rem;
  }

  .ith-image {
    height: 12rem;
    width: 18rem;
    border-radius: 15px;
  }

  .ith-info {
    font-size: 1.2rem;
  }

  .project-tools {
    font-size: 0.9rem;
  }
  .list-item {
    margin-bottom: 1rem;
  }
}

@media all and (max-width: 350px) {
  .about-image {
    margin-top: 1rem;
    width: 150px;
  }

  .ith-image {
    width: 15rem;
    height: 9rem;
  }

  .ith-subtitle {
    font-size: 0.9rem;
  }

  .projects-h1 {
    font-size: 1.5rem;
    margin-left: 1.5rem;
  }

  .project-wrapper {
    margin-top: 1rem;
  }

  .project-tools {
    font-size: 0.8rem;
  }

  .project-tools kbd {
    font-size: 0.8rem;
    padding: 0.3rem;
  }
  .list-item {
    margin-bottom: 1rem;
  }
}
